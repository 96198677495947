$(function() {
  var form = $('#form');
  var formCaptcha = undefined;

  if ($('.captcha-input').length) {
    formCaptcha = new jCaptcha({
      el: '.captcha-input',
      canvasClass: 'input-group-text',
      requiredValue: '',
      canvasStyle: {
        // required properties for captcha stylings:
        width: 50,
        height: 15,
        textBaseline: 'top',
        font: '15px Arial',
        textAlign: 'left',
        fillStyle: '#000',
      },
      // set callback function for success and error messages:
      callback: function ( response, $captchaInputElement, numberOfTries ) {
        if (response === 'success') {
          $('.captcha-input').removeClass('is-invalid');
          sendForm();
        }
        if (response === 'error') {
          $('.captcha-input').addClass('is-invalid');
    
          if (numberOfTries === 3) {
              // maximum attempts handle, e.g. disable form
          }
        }
      }
    });
  }

  $(form).submit(function(event) {
    event.preventDefault();
    if (formCaptcha) {
      formCaptcha.validate();
    } else {
      sendForm();
    }
  });

  var sendForm = function () {
    console.log('sending form!')
    var form = $('#form');
  
    var formMessages = $('#form-messages');
  
    if (form[0].checkValidity() === false) {
      $(formMessages).text('')
      $(formMessages).removeClass('alert-danger');
      $(formMessages).removeClass('alert-success');
      if (formCaptcha) formCaptcha.reset();
      return
    }
  
    var formData = $(form).serialize();
  
    $.ajax({
      type: 'POST',
      url: $(form).attr('action'),
      data: formData
    }).done(function (response) {
      $(formMessages).removeClass('alert-danger');
      $(formMessages).addClass('alert-success');
      $(form).removeClass('was-validated');
      if (formCaptcha) formCaptcha.reset();
      form[0].reset();
  
      $(formMessages).text($(form).attr('successMessage'))
    }).fail(function (response) {
      if (formCaptcha) formCaptcha.reset();
  
      $(formMessages).removeClass('alert-success');
      $(formMessages).addClass('alert-danger');
  
      $(formMessages).text($(form).attr('errorMessage'))
    });
  };
});

window.addEventListener('load', function() {
  // Fetch all the forms we want to apply custom Bootstrap validation styles to
  var forms = document.getElementsByClassName('needs-validation');
  // Loop over them and prevent submission
  var validation = Array.prototype.filter.call(forms, function(form) {
    form.addEventListener('submit', function(event) {
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
      form.classList.add('was-validated');
    }, false);
  });
}, false);