var smoothScrollTo = function(selector) {
  document.querySelector(selector).scrollIntoView({
    behavior: 'smooth'
  });
}

var nav = document.getElementById('nav');
window.onscroll = function () { 
    "use strict";
    if (document.body.scrollTop >= 50 || document.documentElement.scrollTop >= 50 ) {
        nav.classList.add("gj__navbar--scolled");
        nav.classList.remove("gj__navbar--not-scrolled");
    } 
    else {
        nav.classList.remove("gj__navbar--scolled");
        nav.classList.add("gj__navbar--not-scrolled");
    }
};